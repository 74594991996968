import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { FullTeamDto, TeamDto } from '@ledsreact/data-models';

@Injectable()
export class TeamHttpService extends CrudService<TeamDto, FullTeamDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {
    super(http, baseUrl, 'team');
  }

  addPlayerToTeam(teamId: number, playerId: number) {
    return this.http.post<void>(`${this.baseUrl}/${this.name}/${teamId}/player/${playerId}`, null);
  }

  removePlayerFromTeam(teamId: number, playerId: number) {
    return this.http.delete<void>(`${this.baseUrl}/${this.name}/${teamId}/player/${playerId}`, null);
  }
}
