import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { Observable } from 'rxjs';
import { DuplicateOption, FullPlayerDto, Paginated, PlayerDto, ValidationReportDto } from '@ledsreact/data-models';

@Injectable()
export class PlayerHttpService extends CrudService<PlayerDto, FullPlayerDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {
    super(http, baseUrl, 'player');
  }

  getPositionPerSport(): Observable<{ [key: string]: string[] }> {
    return this.http.get<{ [key: string]: string[] }>(`${this.baseUrl}/${this.name}/positions-per-sports`);
  }

  getPlayersWithFiltersForWebPlatform(filters: HttpParams): Observable<Paginated<FullPlayerDto>> {
    return this.http.get<Paginated<FullPlayerDto>>(`${this.baseUrl}/${this.name}/webplatform`, { params: filters });
  }

  validatePlayersCSV(playersCSVFile: File, duplicateOptions: DuplicateOption = null): Observable<ValidationReportDto> {
    const formData: FormData = new FormData();
    formData.append('csvFile', playersCSVFile, playersCSVFile.name);
    formData.append('validationMode', 'true');
    if (duplicateOptions != null) {
      formData.append('duplicateOptions', duplicateOptions.toString());
    }

    return this.http.post<ValidationReportDto>(`${this.baseUrl}/${this.name}/create-many-from-csv`, formData);
  }

  sendPlayersCSV(playersCSVFile: File, duplicateOptions: DuplicateOption = null): Observable<FullPlayerDto[]> {
    const formData: FormData = new FormData();
    formData.append('csvFile', playersCSVFile, playersCSVFile.name);
    formData.append('validationMode', 'false');
    if (duplicateOptions != null) {
      formData.append('duplicateOptions', duplicateOptions.toString());
    }

    return this.http.post<FullPlayerDto[]>(`${this.baseUrl}/${this.name}/create-many-from-csv`, formData);
  }

  downloadCSVTemplate(): Observable<{ signedUrl: string }> {
    return this.http.get<{ signedUrl: string }>(`${this.baseUrl}/${this.name}/download-csv-template`);
  }
}
