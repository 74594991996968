import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConeSoftwareDto, FullConeSoftwareDto } from '@ledsreact/data-models';
import { CrudService } from './crud.abstract.http.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConeSoftwareHttpService extends CrudService<ConeSoftwareDto, FullConeSoftwareDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {
    super(http, baseUrl, 'cone-software');
  }

  public addFirmware(firmware: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/cone-software`, { firmware });
  }
}
