import { Inject, Injectable } from '@angular/core';
import { CrudService } from './crud.abstract.http.service';
import { HttpClient } from '@angular/common/http';
import { FullProfileDto, ProfileDto } from '@ledsreact/data-models';
import { Observable } from 'rxjs';

@Injectable()
export class MemberHttpService extends CrudService<ProfileDto, FullProfileDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {
    super(http, baseUrl, 'member');
  }

  public resetInvitationLink(profileId: number, clubId: number): Observable<null> {
    return this.http.post<null>(`${this.baseUrl}/member/resend-invitation`, { profileId, clubId });
  }
}
