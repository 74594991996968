<table class="ledsreact-table" [style.table-layout]="'fixed'">
  <thead>
    <tr>
      <th></th>
      <th [class.small-column]="sessionIdSelected == null">
        <h5>
          {{ 'LABEL.dateUpload' | translate }}
        </h5>
      </th>
      <th *ngIf="sessionIdSelected == null" class="x-small-column"></th>
      <th *ngIf="isAdminView" class="small-column">
        <h5>
          {{ 'LABEL.club' | translate }}
        </h5>
      </th>
      <th>
        <h5>
          {{ 'LABEL.exercise' | translate }}
        </h5>
      </th>
      <th *ngIf="sessionIdSelected == null">
        <h5>
          {{ 'LABEL.players' | translate }}
        </h5>
      </th>
      <th class="action-column"></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let session of sessionList; let index = index; trackBy: sessionTrackByFn">
      <tr
        [ngClass]="{ 'c-p': !loading, 'attempt-selected': attemptIdSelected != null }"
        [routerLink]="[sessionRoute, session.id]"
        (click)="openRow(session.id)"
        routerLinkActive="selected"
      >
        <ng-container
          *ngTemplateOutlet="sessionRow; context: { session: session, fullSession: true, sessionIndex: index }"
        >
        </ng-container>
      </tr>
      <ng-container *ngIf="sessionOpenMap.has(session.id)">
        <tr
          *ngFor="let attempt of session.attempts; trackBy: attemptTrackByFn"
          [class.c-p]="!loading"
          [routerLink]="[sessionRoute, session.id, attemptRoute, attempt.id]"
          (click)="selectAttempt(attempt.id)"
          routerLinkActive="selected"
        >
          <ng-container *ngIf="sessionIdSelected != null; else fullRow">
            <td></td>
            <td></td>
            <td class="text-ellipsis disp-f">
              <span class="text-ellipsis" *ngIf="attempt?.player">
                {{ attempt.player.firstname }} {{ attempt.player.lastname }}
                <ng-container *ngIf="attempt.player.birthDate | dayMonth as birthDate">
                  <!-- cake.svg -->
                  <svg
                    *ngIf="birthDate === currentDDMM"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    height="16"
                    class="ml-xxs c-red"
                    style="vertical-align: middle; margin-top: -2px"
                    matTooltip="{{ 'LABEL.happyBirthdayPlayer' | translate }}"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z"
                    />
                  </svg>
                  <!-- end cake.svg -->
                </ng-container>
              </span>
              <ng-container *ngIf="attempt?.failedProcessingReason">
                <img
                  *ngIf="attempt.failedProcessingReason === 'TEST_ABORTED'"
                  src="/assets/img/stop.svg"
                  [alt]="'PAGE.GRAPHS_OVERVIEW.FAILED_PROCESSING_REASON.' + attempt.failedProcessingReason | translate"
                  [matTooltip]="
                    'PAGE.GRAPHS_OVERVIEW.FAILED_PROCESSING_REASON.' + attempt.failedProcessingReason | translate
                  "
                  class="ml-xs"
                />
              </ng-container>
            </td>
            <td></td>
            <td class="action-column" align="right">
              <ng-container *ngIf="attempt && !isAdminReadOnly">
                <button class="icon-button" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                  <img src="assets/img/menu-grey.svg" alt="menu" />
                </button>

                <mat-menu
                  class="bs-b5-light-grey"
                  [overlapTrigger]="false"
                  #menu="matMenu"
                  yPosition="below"
                  xPosition="before"
                >
                  <ng-template matMenuContent>
                    <button
                      class="bg-white c-red"
                      (click)="deleteAttempt.emit({ sessionId: session.id, attemptId: attempt.id })"
                      mat-menu-item
                    >
                      <div class="grid-x disp-f a-i-c">
                        <div class="medium-3 cell mt-xxs">
                          <img src="assets/img/trash-red.svg" />
                        </div>
                        <div class="medium-9 cell">
                          {{ 'BUTTON.DELETE_ATTEMPT' | translate }}
                        </div>
                      </div>
                    </button>
                  </ng-template>
                </mat-menu>
              </ng-container>
            </td>
          </ng-container>
          <ng-template #fullRow>
            <ng-container
              *ngTemplateOutlet="sessionRow; context: { session: attempt, fullSession: false, sessionIndex: index }"
            >
            </ng-container>
          </ng-template>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<ng-template #sessionRow let-session="session" let-fullSession="fullSession" let-sessionIndex="sessionIndex">
  <td>
    <ng-container *ngIf="session.lproVersion === lproVersion1">
      <img src="assets/img/v1.svg" height="20" class="mt-xs ml-xs" />
    </ng-container>
  </td>
  <td>
    <div *ngIf="fullSession" class="disp-f align-middle">
      <div
        *ngIf="session.attempts?.length"
        class="pt-xs pb-xs pr-xs pl-xs disp-f align-middle c-p"
        [class.rotate-90]="sessionOpenMap.get(session.id)"
      >
        <img src="assets/img/arrow-right-{{ session.attempts.length ? '' : 'grey-' }}filled.svg" />
      </div>
      <div *ngIf="!session.attempts?.length" class="pt-xs pb-xs pr-xs pl-xs disp-f align-middle">
        <img src="assets/img/arrow-right-grey-filled.svg" />
      </div>
      <div>
        <span class="c-dark-grey">
          {{ session.createdAt | date : 'MMM d y' }}
        </span>
        <span *ngIf="sessionIdSelected != null" class="c-dark-grey ml-xxs">
          {{ session.createdAt | date : 'HH:mm' }}
        </span>
      </div>
    </div>
  </td>
  <td *ngIf="sessionIdSelected == null" class="c-dark-grey x-small-column">
    <ng-container *ngIf="fullSession">
      {{ session.createdAt | date : 'HH:mm' }}
    </ng-container>
  </td>
  <td *ngIf="isAdminView" class="text-ellipsis">
    {{ session.ownerClub?.name }}
  </td>
  <td>
    <ng-container *ngIf="session?.exercise?.name; else templateUid">
      <ng-container *ngIf="session.exercise.public">{{
        'ORIGINAL_EXERCISE.' + session.exercise.name | translate : { Default: session.exercise.name }
      }}</ng-container>
      <ng-container *ngIf="!session.exercise.public">{{ session.exercise.name }}</ng-container>
    </ng-container>
    <ng-template #templateUid>
      <ng-container *ngIf="session.exercise?.template?.uid">
        {{ 'EXERCISE.' + session.exercise.template.uid | translate }}
      </ng-container>
    </ng-template>
  </td>
  <td *ngIf="sessionIdSelected == null" class="text-ellipsis">
    <ng-container *ngIf="session?.player; else playerList">
      {{ session.player.firstname }} {{ session.player.lastname }}
    </ng-container>
    <ng-template #playerList>
      <span *ngFor="let player of session.players; trackBy: playerTrackByFn" class="mr-xxs">
        {{ player.firstname }} {{ player.lastname }}
      </span>
    </ng-template>
  </td>
  <td class="action-column" align="right">
    <ng-container *ngIf="fullSession && !isAdminReadOnly">
      <button class="icon-button" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
        <img src="assets/img/menu-grey.svg" alt="menu" />
      </button>

      <mat-menu class="bs-b5-light-grey" [overlapTrigger]="false" #menu="matMenu" yPosition="below" xPosition="before">
        <ng-template matMenuContent>
          <button class="bg-white c-red" (click)="deleteSession.emit(session.id)" mat-menu-item>
            <div class="grid-x disp-f a-i-c">
              <div class="medium-3 cell mt-xxs">
                <img src="assets/img/trash-red.svg" />
              </div>
              <div class="medium-9 cell">
                {{ 'BUTTON.DELETE_SESSION' | translate }}
              </div>
            </div>
          </button>
        </ng-template>
      </mat-menu>
    </ng-container>
  </td>
  <td></td>
</ng-template>
