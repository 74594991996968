import { HttpParams } from '@angular/common/http';

export class SelectModalUtil {
  static getHttpParamsForModalList(searchField: string, searchValue: string, notGeneric: boolean = false): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('limit', '25');

    if (searchValue) {
      if (notGeneric) {
        httpParams = httpParams.append(searchField, searchValue);
      } else {
        httpParams = httpParams.append('filter', `${searchField}||$cont||${searchValue}`);
      }
    }
    return httpParams;
  }

  static getHttpParamsFromSimpleObject(params: Record<string, any>): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      for (const key of Object.keys(params)) {
        if (params[key] != null) {
          if (typeof params[key] === 'object') {
            if (Array.isArray(params[key])) {
              if (params[key].length) {
                httpParams = httpParams.set(key, JSON.stringify(params[key]));
              }
            } else {
              httpParams = httpParams.set(key, JSON.stringify(params[key]));
            }
          } else {
            httpParams = httpParams.set(key, params[key]);
          }
        }
      }
    }
    return httpParams;
  }
}
