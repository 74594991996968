import { WebplatformEnvironment } from './environment.type';

export const environment: WebplatformEnvironment = {
  production: true,
  trackingEnvironmentName: null,
  environmentName: 'dev',
  api: 'https://api.dev.ledsreact.com/api',
  ws: 'wss://ws.dev.ledsreact.com',
  version: '2.2.0',
  isRestartPostProcessorAllowed: 'always',
};
