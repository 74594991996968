import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { Observable } from 'rxjs';
import { ExerciseSessionDto, FullExerciseSessionDto, ExerciseSessionDebugDataErrorDto } from '@ledsreact/data-models';

@Injectable()
export class ExerciseAttemptHttpService extends CrudService<ExerciseSessionDto, FullExerciseSessionDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl) {
    super(http, baseUrl, 'exercise-attempt');
  }

  getAttemptsErrorData(): Observable<ExerciseSessionDebugDataErrorDto[]> {
    return this.http.get<ExerciseSessionDebugDataErrorDto[]>(`${this.baseUrl}/exercise-attempt/attempt-error-data`);
  }
}
