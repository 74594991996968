import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { FirmwareVersionDto, FullFirmwareVersionDto } from '@ledsreact/data-models';

@Injectable()
export class FirmwareVersionHttpService extends CrudService<FirmwareVersionDto, FullFirmwareVersionDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {
    super(http, baseUrl, 'firmware-version');
  }
}
