import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { FullSportDto, SportDto } from '@ledsreact/data-models';

@Injectable()
export class SportHttpService extends CrudService<SportDto, FullSportDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {
    super(http, baseUrl, 'sport');
  }
}
