import { Injectable } from '@angular/core';
import { ExerciseSessionHttpService } from './exercise-session.http.service';
import { LiveDataDto } from '@ledsreact/data-models';
import { BehaviorSubject, firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';

@Injectable()
export class LiveExerciseService {
  private readonly _liveDataByUuid$: Map<string, BehaviorSubject<LiveDataDto[]>> = new Map();
  readonly liveDataByUuid$: Map<string, Observable<LiveDataDto[]>> = new Map();

  private readonly _checkLiveDataIntervalsByUuid: Map<
    string,
    {
      timeout: ReturnType<typeof setTimeout>;
      i: number;
      currentInterval: number;
      cancelHttp$: Subject<void>;
      refCount: number;
    }
  > = new Map();

  constructor(private readonly _exerciseSessionHttpService: ExerciseSessionHttpService) {}

  getLiveData$(exerciseSessionUuid: string): Observable<LiveDataDto[]> {
    if (!this._liveDataByUuid$.has(exerciseSessionUuid)) {
      this._liveDataByUuid$.set(exerciseSessionUuid, new BehaviorSubject([]));
      this.liveDataByUuid$.set(exerciseSessionUuid, this._liveDataByUuid$.get(exerciseSessionUuid).asObservable());
    }
    return this.liveDataByUuid$.get(exerciseSessionUuid);
  }

  /**
   * Starts checking live data for a given exercise session uuid
   * using an exponential backoff interval strategy.
   *
   * Every time this function is called, it will reset the interval.
   *
   * @param exerciseSessionUuid
   * @param initialInterval
   * @param stopOnNewData
   * @param {number} maxTries - Maximum number of tries before stopping the interval
   * @param {number} maxInterval - Maximum interval between checks
   */
  async checkLiveData(
    exerciseSessionUuid: string,
    waitForAttemptID: number | null = null,
    initialInterval = 3000,
    stopOnNewData = true,
    maxTries = 30,
    maxInterval = 5000
  ): Promise<void> {
    if (this._checkLiveDataIntervalsByUuid.has(exerciseSessionUuid)) {
      const intervalData = this._checkLiveDataIntervalsByUuid.get(exerciseSessionUuid);
      clearTimeout(intervalData.timeout);
      intervalData.i = 0;
      intervalData.currentInterval = initialInterval;
      intervalData.refCount++;
    } else {
      this._checkLiveDataIntervalsByUuid.set(exerciseSessionUuid, {
        timeout: null,
        i: 0,
        currentInterval: initialInterval,
        cancelHttp$: new Subject(),
        refCount: 1,
      });
    }

    const intervalData = this._checkLiveDataIntervalsByUuid.get(exerciseSessionUuid);

    const executeCheck = async () => {
      const result = await this.checkLiveDataOnce(exerciseSessionUuid, intervalData.cancelHttp$);
      intervalData.i++;

      if (result) {
        if (waitForAttemptID) {
          const attempt = result.find((r) => r.attemptID === waitForAttemptID);
          if (attempt) {
            this.decrementRefCount(exerciseSessionUuid);
            return;
          }
        } else {
          if (stopOnNewData) {
            this.decrementRefCount(exerciseSessionUuid);
            return;
          }
        }
      }

      if (intervalData.i > maxTries) {
        this.decrementRefCount(exerciseSessionUuid);
        return;
      }

      if (intervalData.currentInterval < maxInterval) {
        intervalData.currentInterval *= 1.2; // Exponential backoff
      } else {
        intervalData.currentInterval = maxInterval;
      }
      intervalData.timeout = setTimeout(executeCheck, intervalData.currentInterval);
    };

    intervalData.timeout = setTimeout(executeCheck, intervalData.currentInterval);
  }

  async checkLiveDataOnce(exerciseSessionUuid: string, cancelHttp$?: Observable<void>): Promise<LiveDataDto[] | null> {
    let httpCall$ = this._exerciseSessionHttpService.getLiveData(exerciseSessionUuid);
    if (cancelHttp$) {
      httpCall$ = httpCall$.pipe(takeUntil(cancelHttp$));
    }
    try {
      const liveDataRes = await firstValueFrom(httpCall$);
      if (liveDataRes.length) {
        const subject$ = this._getLiveDataSubject$(exerciseSessionUuid);
        if (subject$.value.length !== liveDataRes.length) {
          subject$.next(liveDataRes);
          return liveDataRes;
        }
      }
    } catch (_error) {
      // Ignore errors
    }
    return null; // no new data
  }

  /**
   * Stops the interval for a specific exercise session uuid.
   *
   * @param exerciseSessionUuid
   */
  stopLiveDataCheck(exerciseSessionUuid: string): void {
    this.decrementRefCount(exerciseSessionUuid);
  }

  /**
   * Stops all ongoing intervals.
   */
  stopAllLiveDataChecks(): void {
    this._checkLiveDataIntervalsByUuid.forEach((intervalData, _uuid) => {
      intervalData.cancelHttp$.next();
      intervalData.cancelHttp$.complete();
      clearTimeout(intervalData.timeout);
    });
    this._checkLiveDataIntervalsByUuid.clear();
  }

  private _getLiveDataSubject$(exerciseSessionUuid: string): BehaviorSubject<LiveDataDto[]> {
    if (!this._liveDataByUuid$.has(exerciseSessionUuid)) {
      this._liveDataByUuid$.set(exerciseSessionUuid, new BehaviorSubject([]));
      this.liveDataByUuid$.set(exerciseSessionUuid, this._liveDataByUuid$.get(exerciseSessionUuid).asObservable());
    }
    return this._liveDataByUuid$.get(exerciseSessionUuid);
  }

  /**
   * Decrements the reference count and stops the interval if it reaches zero.
   *
   * @param exerciseSessionUuid
   */
  private decrementRefCount(exerciseSessionUuid: string): void {
    if (this._checkLiveDataIntervalsByUuid.has(exerciseSessionUuid)) {
      const intervalData = this._checkLiveDataIntervalsByUuid.get(exerciseSessionUuid);
      intervalData.refCount--;
      if (intervalData.refCount <= 0) {
        intervalData.cancelHttp$.next();
        intervalData.cancelHttp$.complete();
        clearTimeout(intervalData.timeout);
        this._checkLiveDataIntervalsByUuid.delete(exerciseSessionUuid);
      }
    }
  }
}
