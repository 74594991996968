import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { Observable } from 'rxjs';
import { ClubDto, FeaturesDto, FullClubDto, FullProfileDto, Paginated, ProfileDto } from '@ledsreact/data-models';

@Injectable()
export class ClubHttpService extends CrudService<ClubDto, FullClubDto> {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {
    super(http, baseUrl, 'club');
  }

  getClubsWithFiltersForWebPlatform(filters: HttpParams): Observable<Paginated<ClubDto>> {
    return this.http.get<Paginated<ClubDto>>(`${this.baseUrl}/${this.name}/webplatform`, { params: filters });
  }

  public addImage(clubId: number, image: any): Observable<ClubDto> {
    return this.http.post<ClubDto>(`${this.baseUrl}/${this.name}/${clubId}/image`, { image });
  }

  public findClubMembers(clubId: number, httpParams?: {}): Observable<Paginated<FullProfileDto>> {
    return this.http.get<Paginated<FullProfileDto>>(`${this.baseUrl}/${this.name}/${clubId}/member`, {
      params: httpParams,
    });
  }

  public partialUpdateClubMember(idClub: number, idMember: number, t: Partial<ProfileDto>): Observable<FullProfileDto> {
    return this.http.patch<FullProfileDto>(`${this.baseUrl}/${this.name}/${idClub}/member/${idMember}`, t, {});
  }

  public saveClubMember(clubId: number, t: ProfileDto): Observable<FullProfileDto> {
    return this.http.post<FullProfileDto>(`${this.baseUrl}/${this.name}/${clubId}/member`, t);
  }

  public deleteClubMember(idClub: number, idMember: number): Observable<null> {
    return this.http.delete<null>(`${this.baseUrl}/${this.name}/${idClub}/member/${idMember}`);
  }

  public getFeatures(): Observable<FeaturesDto[]> {
    return this.http.get<FeaturesDto[]>(`${this.baseUrl}/${this.name}/features`);
  }
}
