import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FullExerciseAttemptDto,
  FullExerciseSessionDto,
  FullPlayerDto,
  LedsreactProVersion,
} from '@ledsreact/data-models';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { InsightsService } from '../insights.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@webplatform/shared/services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-session-table',
  templateUrl: './session-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionTableComponent implements OnInit {
  @Input() set list(value: FullExerciseSessionDto[]) {
    this.sessionList = value;
  }

  @Input() loading: boolean;

  @Output() readonly deleteSession: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly deleteAttempt: EventEmitter<{ sessionId: number; attemptId: number }> = new EventEmitter<{
    sessionId: number;
    attemptId: number;
  }>();

  readonly sessionRoute: AppRoutingEnum = AppRoutingEnum.SESSION;
  readonly attemptRoute: AppRoutingEnum = AppRoutingEnum.ATTEMPT;

  isAdminReadOnly: boolean;
  isAdminView: boolean;
  sessionIdSelected: number = null;
  attemptIdSelected: number = null;
  sessionList: FullExerciseSessionDto[];
  sessionOpenMap: Map<number, boolean> = new Map<number, boolean>();
  lproVersion1 = LedsreactProVersion.V1;
  currentDate = new Date();
  currentDDMM = `${this.currentDate.getDate().toString().padStart(2, '0')}/${(this.currentDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}`;

  constructor(
    private _insightsService: InsightsService,
    private _userService: UserService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isAdminReadOnly = this._userService.user.isAdminReadOnly;
    this.isAdminView = this._userService.user.isAdmin || this._userService.user.isAdminReadOnly;
    this._insightsService
      .getCurrentIdSessionObs$()
      .pipe(untilDestroyed(this))
      .subscribe((value: number) => {
        this.sessionIdSelected = value;
        if (this.sessionIdSelected != null && !this.sessionOpenMap.get(this.sessionIdSelected)) {
          this.openRow(this.sessionIdSelected);
        }
        this._cd.markForCheck();
      });
    this._insightsService
      .getCurrentIdAttemptObs$()
      .pipe(untilDestroyed(this))
      .subscribe((value: number) => {
        this.attemptIdSelected = value;
        this._cd.markForCheck();
      });
  }

  openRow(idSession: number) {
    this.attemptIdSelected = null;
    if (this.sessionOpenMap.get(idSession) && this.sessionIdSelected === idSession && this.attemptIdSelected == null) {
      this.sessionOpenMap.delete(idSession);
    } else {
      this.sessionOpenMap.set(idSession, true);
    }
  }

  selectAttempt(attemptId: number) {
    if (attemptId !== this.attemptIdSelected) {
      this._insightsService.resetCurrentAttemptAndCurrentAttemptGraphs();
    }
    this.attemptIdSelected = attemptId;
    this._cd.markForCheck();
  }

  sessionTrackByFn(_: number, session: FullExerciseSessionDto) {
    return session.id;
  }

  attemptTrackByFn(_: number, attempt: FullExerciseAttemptDto) {
    return attempt.id;
  }

  playerTrackByFn(_: number, player: FullPlayerDto) {
    return player.id;
  }
}
